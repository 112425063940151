<div
    class="bg-white rounded-lg shadow-lg max-h-75vh m-4 flex flex-col"
    [ngClass]="{
        'max-w-lg': options.size === 'sm',
        'max-w-2xl': options.size === 'md' || !options.size,
        'max-w-5xl': options.size === 'lg',
        'max-w-screen': options.size === 'xl'
    }"
>
    <div class="flex justify-between items-baseline p-4 space-x-4">
        <h5 class="text-lg"><ng-container *ngTemplateOutlet="titleTemplateRef$ | async"></ng-container></h5>
        <button
            *ngIf="options?.closable"
            type="button"
            (click)="closeModal()"
            class="text-gray-500"
            data-dismiss="modal"
            aria-label="Close"
        >
            <fa-icon [icon]="times"></fa-icon>
        </button>
    </div>
    <div class="p-4 flex-1 overflow-y-auto">
        <vsf-dialog-component-outlet
            [component]="childComponentType"
            (create)="onCreate($event)"
        ></vsf-dialog-component-outlet>
    </div>
    <div class="p-4 text-right">
        <ng-container *ngTemplateOutlet="buttonsTemplateRef$ | async"></ng-container>
    </div>
</div>
